import { FormItemRule } from "naive-ui";
import {
  minMaxLengthMessage,
  requiredMessage,
} from "@/composables/validationMessages";
import addressStringVerificationRules from "@/validators/addressStringVerificationRules";

const addressValidationRules = {
  country: addressStringVerificationRules("Country"),
  city: addressStringVerificationRules("City"),
  address: addressStringVerificationRules("Address"),
  postalCode: {
    required: true,
    validator(_rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (!value) {
          reject(Error(requiredMessage("Postal code")));
        } else if (!(value.length >= 3 && value.length <= 10)) {
          reject(Error(minMaxLengthMessage("Postal code", 3, 10)));
        } else {
          resolve();
        }
      });
    },
    trigger: ["input", "blur"],
  },
};

export default addressValidationRules;
