import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1923b992"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "checkout--wrapper"
}
const _hoisted_2 = { class: "checkout--container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
    _createVNode($setup["QuoteCheckoutHeader"], {
      title: "Checkout",
      quote: $setup.quote
    }, null, 8, ["quote"]),
    (!$setup.initialLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["QuoteDetailsOptions"], {
              editable: false,
              withSummary: false,
              options: $setup.quote.options,
              countOfWorkingDays: $setup.countOfWorkingDays,
              partProductionLocations: $setup.quote.partProductionLocations,
              orderedAt: $setup.quote.orderedAt,
              shippingAddress: $setup.quote.shippingAddressId,
              onUpdateSummarySectionHeight: $setup.onUpdateSummarySectionHeight
            }, null, 8, ["options", "countOfWorkingDays", "partProductionLocations", "orderedAt", "shippingAddress"]),
            _createVNode($setup["PartsAndSpecificationsTable"], {
              class: "checkout--parts",
              parts: $setup.parts,
              options: $setup.quote.options,
              currencySign: $setup.quote.currencyId?.symbol
            }, null, 8, ["parts", "options", "currencySign"])
          ]),
          _createVNode($setup["SummarySection"], {
            class: "checkout--summary",
            parts: $setup.parts,
            options: $setup.quote.options,
            disabled: $setup.quote.isDeleted,
            orderedAt: $setup.quote.orderedAt,
            currencySign: $setup.quote.currencyId?.symbol,
            countOfWorkingDays: $setup.countOfWorkingDays,
            onPurchaseOrderPay: $setup.purchaseOrderPay,
            style: _normalizeStyle({height: `${$setup.summarySectionHeight}px`})
          }, null, 8, ["parts", "options", "disabled", "orderedAt", "currencySign", "countOfWorkingDays", "style"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}